nav.pagy.nav {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;

  a:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  a:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  a:not(.gap) {
    background: var(--base-bg-lowest);
    border: 1px solid var(--base-border-tertiary);
    color: var(--text-primary);
    padding: 8px 12px;
    position: relative;
    display: block;
    font-variant-numeric: tabular-nums;
    margin-left: -1px;

    &:hover {
      background: var(--base-bg-hover);
      color: var(--text-primary-hover);
    }

    &[aria-current="page"].current {
      background: var(--bg-primary);
      color: var(--text-on-primary);
      margin-left: -1px;
    }

    &:not([href]) {
      background: var(--base-bg-lowest);
      color: var(--base-text-tertiary);
      margin-left: -1px;
    }
  }
}
