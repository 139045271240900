/* A couple handy classes for responsive YouTube and other iframe embeds */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[data-controller="stripe--embedded-checkout"] iframe {
  @apply rounded-lg;
}
