.nav-link {
  @apply block no-underline px-2 py-1 text-base font-medium whitespace-nowrap;
  line-height: 30px;

  &.active {
    @apply font-semibold;
  }

  &:focus {
    @apply text-gray-900;
  }
}

@media (min-width: 1024px) {
  .nav-link {
    @apply inline-flex my-0 mr-3;
    line-height: 40px;
  }
}

.dropdown-menu {
  min-width: 100%;

  @screen lg {
    min-width: 200px;
  }
}
