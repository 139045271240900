@charset "UTF-8";
trix-editor {
  @apply form-control;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

trix-toolbar {
  @apply form-control;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: top 0.3s ease-in-out;
}

trix-toolbar .trix-button--dialog {
  @apply btn btn-small btn-primary;
}

trix-toolbar .trix-dialog__link-fields .trix-button-group {
  .trix-button--dialog:last-of-type {
    @apply ml-1;
  }
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-dialog__link-fields {
    display: block;

    .trix-button-group {
      @apply mt-2;
    }
  }
}

/* Dark mode customization */
html.dark .trix-content pre {
  @apply bg-gray-800 text-white;
}
