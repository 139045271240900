@layer components {
  .btn-twitter {
    background-color: #4099ff;
    color: white;
    @apply flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-colors;
  }
  .btn-twitter:hover {
    background-color: #3888e6;
  }
  .btn-twitter:focus {
    @apply outline-none ring-2 ring-offset-2;
  }

  .btn-facebook {
    background-color: #3b5998;
    color: white;
    @apply flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-colors;
  }
  .btn-facebook:hover {
    background-color: #324c82;
  }
  .btn-facebook:focus {
    @apply outline-none ring-2 ring-offset-2;
  }

  .btn-github {
    background-color: #333333;
    color: white;
    @apply flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-colors;
  }
  .btn-github:hover {
    background-color: #2b2b2b;
  }
  .btn-github:focus {
    @apply outline-none ring-2 ring-offset-2;
  }

  .btn-google_oauth2 {
    background-color: #d34836;
    color: white;
    @apply flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-colors;
  }
  .btn-google_oauth2:hover {
    background-color: #be3f2f;
  }
  .btn-google_oauth2:focus {
    @apply outline-none ring-2 ring-offset-2;
  }

  .btn-microsoft_graph {
    background-color: white;
    color: #0f172a;
    border: 1px solid #0f172a;
    @apply flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-colors;
  }
  .btn-microsoft_graph:hover {
    background-color: #f8fafc;
  }
  .btn-microsoft_graph:focus {
    @apply outline-none ring-2 ring-offset-2;
  }
}
