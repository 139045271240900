@layer components {
  .pill {
    font-family: Inter;
    border-radius: 9999px;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    line-height: 16px;
    text-transform: uppercase;
    background: var(--base-bg-highest);
    color: var(--base-text-secondary);
    display: inline-flex;
    gap: 8px;
    align-items: flex-start;
  }
  .pill-outline {
    background: transparent;
    border: 1px solid var(--base-border-secondary);
    color: var(--base-text-secondary);
  }
  .pill-primary {
    background: var(--bg-primary-hover);
    color: var(--text-on-primary);

    &.pill-outline {
      background: transparent;
      border: 1px solid var(--border-primary-hover);
      color: var(--text-primary-hover);
    }
  }
  .pill-secondary {
    background: var(--bg-success-hover);
    color: var(--text-on-success);
    
    &.pill-outline {
      background: transparent;
      border: 1px solid var(--border-success-focus);
      color: var(--border-success-focus);
    }
  }
  .pill-tertiary {
    background: var(--bg-dark-secondary); 
    color: var(--text-on-dark);  

    &.pill-outline {
      background: transparent;
      border: 1px solid var(--base-text-tertiary); 
      color: var(--base-text-tertiary); 
    }
  }
  .pill-accent {
    background: var(--bg-accent);
    color: var(--text-on-accent);

    &.pill-outline {
      background: transparent;
      border: 1px solid var(--border-accent);
      color: var(--text-accent);
    }
  }
}
