@layer components {
  .card {
    background: var(--base-bg-low);
    color: var(--base-text);
    border: 1px solid var(--base-border-secondary);
    border-radius: 8px;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 32px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .card-cta-basic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .card-image {
    padding: 0px;
    overflow: hidden;
    width: 100%;

    & > :last-child {
      padding: 24px;
    }
  }

  @media (min-width: 1024px) {
    .card.card-image {
      width: 50%;
    }
  }
} 
