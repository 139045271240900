.tippy-box[data-theme~="default"] {
  background-color: var(--base-bg-tooltip);
  opacity: 0.95;
  color: var(--base-text-on-tooltip);
  border-radius: 4px;
  padding: 8px;
  box-shadow:
    0px 4px 6px -1px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.tippy-box[data-theme~="default"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: var(--base-bg-tooltip);
}

.tippy-box[data-theme~="default"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--base-bg-tooltip);
}

.tippy-box[data-theme~="default"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: var(--base-bg-tooltip);
}

.tippy-box[data-theme~="default"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: var(--base-bg-tooltip);
}
